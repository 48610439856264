<template>
  <div>
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name : 'Logout',
    data() {
      return {
        loading : false,
      }
    },
    computed : {
      ...mapGetters({
        'logoutStatus' : 'auth/logoutStatus',
      }),
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    watch : {
      logoutStatus(val) {
        if (val)
          this.loading = (val === 'loading') ? true : false;
      },
    },
    mounted() {
      if (this.$store.getters['auth/isLoggedIn'])
        this.$store.dispatch('auth/logout');
      else
        this.$router.replace('/');
    },
  }
</script>